/* eslint-disable no-else-return */
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import PropTypes from "prop-types";
import iconSearch from "assets/img/icon-search.svg";
import Modal from "modules/common/components/Modal";
import { reactSelectCustomStyle, scrollToTop } from "helper";
import { removeItem, set } from "session";
import imgPlaceHolder from "assets/img/transaction/icon-drilling.svg";
import MiniLoader from "modules/common/components/MiniLoader";
import Select from "react-select";
import NumberFormat from 'react-number-format';
import useFormValidator from "useFormValidator";
import EmptyState from "modules/common/components/EmptyState";
import RPTSearchBilling from "modules/transaction/containers/RPTAX-ANGELES/containers/RPTSearchNew";
// import RPTSearchCSV from "modules/transaction/containers/RPT/containers/csv/RPTSearchCSV";
import BPLSSearchBilling from "modules/transaction/containers/TBPLS/container/BPLSSearchBilling";

import BTAXSearchBilling from "modules/transaction/containers/BTAX/containers/BTAXSearchBilling";
import BTAXSearchCSV from "modules/transaction/containers/BTAX/containers/csv/BTAXSearchCSV";
import NOVSearchBilling from "modules/transaction/containers/NOV/containers/SearchBilling";
import CmsContext from "../../../CmsContext";
// import SearchBIN from "modules/transaction/containers/BPLS/containers/BusinessPermitRenew/containers/SearchBIN";
import * as actions from "../actions";
import * as c from "../constants";
import MiscellaneousFees from "./MISCELLANEOUS-FEES/containers/MiscellaneousFees";

const form = {
  system_module_id: "",
  application_status_id: "",
  transaction_type: "",
  module_type: "",
  icon: "",
  label: ""
};

function SelectTransactionModal({
  moduleTypeList,
  isLoading,
  getSelectedModuleSystem,
  selectedModuleSystem,
  history,
  match,
  selectedModuleSystemIsLoading,
  initFormData,
  initActiveContent,
  getBillingsImport,
  getOfficesList,
  officesList,
  getMiscFeesList,
  miscFeesList,
  billingSettings,
  bplsBillingSettings
  // getBPLSBillingsImport,
}) {
  const setModal = useContext(ModalContext);
  const cmsContext = useContext(CmsContext);
  const CMS_ICONS = cmsContext.findByType("ICONS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");
  const [activeContent, setActiveContent] = useState(initActiveContent);
  const [formData, setFormData] = useState(initFormData);

  const officeOptions =
    officesList?.map(
      item => {
        const data = {
          label: `${_.get(item, "attributes.name").toUpperCase()}`,
          value: _.get(item, "id"),
          data: item
        };
        return data;
      }
    ) || [];

  const miscFeeOptions =
    miscFeesList?.map(
      item => {
        const data = {
          label: `${_.get(item, "attributes.account_title").toUpperCase()}`,
          value: _.get(item, "id"),
          data: item
        };
        return data;
      }
    ) || [];

  const validateForm = useFormValidator(formData, {
    application_status_id: "required",
    amount: "number",
  });

  useEffect(() => {
    validateForm.onSetObjectRules({
      application_status_id: "required",
      amount: "number",
    })
    if (_.get(selectedModuleSystem, "attributes.description", 0) === 'Miscellaneous Fees'
      && _.get(miscFeeOptions?.find(item =>
        _.get(formData, "application_status_id") ===
        _.get(item, "value")
      ), 'data.attributes.amount') === "0.00") {
        validateForm.onSetObjectRules({
          application_status_id: "required",
          amount: "number_range:20-1000000",
        })
      }
  }, [_.get(formData, "application_status_id")])

  useEffect(() => {
    validateForm.validate();
  }, [Object.values(formData).join(",")]);

  useEffect(() => {
    if (initActiveContent) getSelectedModuleSystem(initActiveContent);
  }, [initActiveContent]);

  const handleOnClose = e => {
    if (e) e.preventDefault();
    setModal({ isOpen: false });
  };

  useEffect(() => {
    getBillingsImport();
    getOfficesList();
    // getBPLSBillingsImport();
  }, []);

  const handleSetActiveContent = item => e => {
    e.preventDefault();
    if (item) {
      setFormData({
        ...formData,
        module_type: _.get(item, "attributes.name"),
        icon: _.get(item, "attributes.photo"),
        label: _.get(item, "attributes.description")
      });
      getSelectedModuleSystem(item.id);
    }

    if (item === 0) {
      setFormData(form);
    }

    // redirect to /create-transaction/rptax-billing without going to modal dropdown
    if (item.id === "1" && _.get(CMS_COMPONENTS, "RPTAX_HAS_OR")) {
      localStorage.setItem(
        "transaction",
        JSON.stringify({
          module_type: _.get(item, "attributes.name"),
          icon: _.get(item, "attributes.photo"),
          label: _.get(item, "attributes.description"),
          system_module_id: _.get(
            item,
            "attributes.application_statuses.0.system_module_id"
          ), // temp. static value
          transaction_type: _.get(
            item,
            "attributes.application_statuses.0.name"
          ), // temp. static value
          application_status_id: _.get(
            item,
            "attributes.application_statuses.0.id"
          ).toString() // temp. static value
        })
      );

      history.push(`${match.path}create-transaction/rptax-billing`);
      setModal({ isOpen: false });
    }
    setActiveContent(item.id);
    scrollToTop();
  };

  const handleOnChangeSelect = key => e => {
    const args = {
      ...formData,
      [key]: e.value,
      system_module_id: _.get(e, "data.attributes.system_module_id"),
      transaction_type: _.get(e, "data.attributes.name"),
      amount: _.get(e, 'data.attributes.amount') || '0.00',
    }
    if(key === 'office_id'){
      getMiscFeesList({[key]: e.value});
      return setFormData({
        ...args,
        amount: key === 'office_id' && _.get(formData, 'office_id') === e.value ? _.get(formData, 'amount') : _.get(e, 'data.attributes.amount') || '0.00',
        application_status_id: _.get(formData, 'office_id') === e.value ? _.get(formData, 'application_status_id') : "",
      });
    }
    return setFormData(args);
  };

  const onChangeNumber = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleOnProceed = () => {
    localStorage.setItem("transaction", JSON.stringify(formData));
    const modType = _.get(formData, "module_type");
    const transType = _.get(formData, "transaction_type");
    const rootUrl = `${match.path}create-transaction`;

    const getRedirect = type => {
      try {
        const module = {
          T_BPLS: () => {
            // BPLS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/business-permit-new`,
                RENEWAL: `${rootUrl}/business-permit-renew`
                // TEMPORARY: `${rootUrl}/business-permit-new-comp`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          BPLS: () => {
            // BPLS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/e2e-business-permit-new`,
                RENEWAL: `${rootUrl}/e2e-business-permit-renew`,
                CHANGE: `${rootUrl}/e2e-business-permit-change`,
                QUARTERLY: `${rootUrl}/e2e-business-permit-quarterly`,
                DELINQUENT: `${rootUrl}/e2e-business-permit-delinquent`,
                RETIREMENT: `${rootUrl}/e2e-business-permit-retirement`
                // TEMPORARY: `${rootUrl}/business-permit-new-comp`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          MVOPS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/motorized-permit-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          SCMS: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/senior-citizen-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          CDL: () => {
            const getTransactionType = item => {
              const x = {
                INDIVIDUAL: `${rootUrl}/cedula-individual-new`,
                CORPORATION: `${rootUrl}/cedula-corporation-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          PSA: () => {
            const getTransactionType = item => {
              const x = {
                "BIRTH CERTIFICATE": `${rootUrl}/birth-certificate-new`,
                "DEATH CERTIFICATE": `${rootUrl}/death-certificate-new`,
                "MARRIAGE CERTIFICATE": `${rootUrl}/marriage-certificate-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OHP: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/occupational-health-new`,
                RENEWAL: `${rootUrl}/occupational-health-renew`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          HC: () => {
            const getTransactionType = item => {
              const x = {
                // NEW: `${rootUrl}/health-certificate`,
                'AMBULANT': `${rootUrl}/health-certificate-ambulant`,
                'CITY EMPLOYEE': `${rootUrl}/health-certificate-city-employee`,
                'CONTRACT OF SERVICE': `${rootUrl}/health-certificate-contract-service`,
                'MARKET VENDORS': `${rootUrl}/health-certificate-market-vendor`,
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OP: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/occupational-permit`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          OVRS: () => {
            const getTransactionType = item => {
              const x = {
                "OVR PAYMENT": `${rootUrl}/ordinance-violation-system`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          PC: () => {
            const getTransactionType = item => {
              const x = {
                NEW: `${rootUrl}/police-clearance-new`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          RPTAX: () => {
            const getTransactionType = item => {
              const x = {
                "TAX PAYMENT": loadRPTAX(
                  _.get(billingSettings, "0.attributes.source"),
                  formData,
                  rootUrl
                ),
                "TAX CLEARANCE": `${rootUrl}/tax-clearance`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          EHELP: () => {
            const getTransactionType = item => {
              const x = {
                "Electronic Immunization Registration": `${rootUrl}/vaccination`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
          RPT: () => {
            const getTransactionType = item => {
              const x = {
                "RPT Land Payment": `${rootUrl}/rpt-land-transaction`,
                "RPT Building Payment": `${rootUrl}/rpt-building-transaction`,
                "RPT Machinery Payment": `${rootUrl}/rpt-machinery-transaction`
              };
              return x[item];
            };
            return getTransactionType(transType);
          },
        };
        return module[type] && module[type]();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Something went wrong!");
        return "/";
      }
    };
    // adjust this line if retrieve features is needed
    if (transType === "RENEW") {
      set("RETRIEVE_OLD_DATA_MODAL", 1);
    }

    history.push(getRedirect(modType));
    removeItem("application-info");
    handleOnClose();
  };

  const loadRPTAX = (settings, dataForm, rootUrl) => {
    if (settings === "CSV") {
      return `${rootUrl}/rptax-csv`;
      // return  <RPTSearchCSV module={dataForm} />;
    }
    return `${rootUrl}/rptax-billing`;
    // return <RPTSearchBilling module={dataForm} />;
  };

  const loadBTAX = (settings, dataForm) => {
    if (settings === "CSV") {
      return <BTAXSearchCSV module={dataForm} />;
    }
    return <BTAXSearchBilling module={dataForm} />;
  };

  const loadTBPLS = (settings, dataForm) => {
    // if (settings === "CSV") {
    //   return <BPLSSearchBilling module={dataForm} />;
    // }
    return <BPLSSearchBilling module={dataForm} />;
  };

  const hasProceedButton = () => {
    let arr = ["BTAX", "NOV"];

    if (
      _.get(formData, "transaction_type") === "DELINQUENT" ||
      _.get(formData, "transaction_type") === "QUARTERLY"
    ) {
      arr = [...arr, "T_BPLS"];
    }

    if (_.get(CMS_COMPONENTS, "RPTAX") === "ANGELES") {
      return arr.concat("RPTAX");
    }

    return arr;
  };

  const renderSelectedModule = () => {
    const options =
      _.get(selectedModuleSystem, "included.applicationStatuses", []).map(
        item => {
          const subTitle =
            (_.get(item, "attributes.name").indexOf("QUARTERLY") > -1 &&
              " (Current Year)") ||
            (_.get(item, "attributes.name").indexOf("DELINQUENT") > -1 &&
              " (Previous Year)") ||
            "";

          const data = {
            label: `${_.get(item, "attributes.name")} ${!_.isEmpty(_.get(item, "attributes.description_filipino")) ||
              !_.isEmpty(subTitle)
              ? "|"
              : ""
              } ${_.get(item, "attributes.description_filipino") ||
              ""} ${subTitle}`,
            value: _.get(item, "id"),
            data: item
          };
          return data;
        }
      ) || [];
    
    return selectedModuleSystemIsLoading ? (
      <div
        className="d-flex align-items-center"
        style={{
          height: 414
        }}
      >
        <MiniLoader />
      </div>
    ) : (
      <>
        <div className="row d-flex justify-content-center mb-3  animated fadeIn">
          <div className="col-12 col-md-10 col-lg-8 p-2">
            <div className="item-card-active cms-outline-primary">
              <div className="container justify-content-center align-items-center h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-4 pl-4 pr-2 d-flex justify-content-center align-items-end">
                    {+_.get(selectedModuleSystem, "attributes.is_active", 0) ===
                      0 && (
                        <i
                          className="fa fa-times text-danger h3 icon-circle"
                          style={{ top: 0, left: 10, position: "absolute" }}
                        />
                      )}
                    {+_.get(selectedModuleSystem, "attributes.is_active", 1) ===
                      1 && (
                        <i
                          className="fa fa-check text-success h3 icon-circle"
                          style={{ top: 0, left: 10, position: "absolute" }}
                        />
                      )}
                    <img
                      className="transaction-icon"
                      src={
                        _.get(
                          selectedModuleSystem,
                          "attributes.other_information"
                        )
                          ? _.get(
                            _.get(
                              CMS_ICONS,
                              _.get(
                                selectedModuleSystem,
                                "attributes.other_information"
                              ),
                              {}
                            ),
                            "cdnUrl",
                            ""
                          )
                          : imgPlaceHolder
                      }
                      alt="Icon_Module"
                    />
                  </div>
                  <div className="col pl-0 d-flex flex-column justify-content-start align-items-start">
                    <h3 className="cms-text-primary">
                      {_.get(selectedModuleSystem, "attributes.description")}
                    </h3>
                    <h6 className="cms-text-primary">
                      {_.get(
                        selectedModuleSystem,
                        "attributes.description_filipino"
                      )}
                    </h6>
                    {/* <i className='text-info'>
                      {_.get(
                        selectedModuleSystem,
                        "attributes.description"
                      ) || ""}
                    </i> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 1 && (
            <div className="col-12">
              <EmptyState message="Soon to be available" />
            </div>
          )}
        {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 0 &&
          _.get(selectedModuleSystem, "attributes.description", 0) === 'Miscellaneous Fees' && (
            <>
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="form-wrapper mb-3">
                    <div className="full-group ">
                      <div className="form-row">
                        <div className="form-input-group col-12">
                          <label
                            htmlFor="last_name"
                            className="full-label font-weight-bold mb-0"
                          >
                            Office
                          </label>
                          <Select
                            styles={reactSelectCustomStyle}
                            value={
                              officeOptions.find(
                                item =>
                                  _.get(formData, "office_id") ===
                                  _.get(item, "value")
                              ) || ""
                            }
                            onChange={handleOnChangeSelect(
                              "office_id"
                            )}
                            options={officeOptions}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-input-group col-12">
                          <label
                            htmlFor="last_name"
                            className="full-label font-weight-bold mb-0"
                          >
                            Type of Miscellaneous Fee
                            {validateForm.renderMessage("application_status_id")}
                          </label>
                          <Select
                            styles={reactSelectCustomStyle}
                            isDisabled={_.isEmpty(_.get(formData, 'office_id'))}
                            value={
                              miscFeeOptions.find(
                                item =>
                                  _.get(formData, "application_status_id") ===
                                  _.get(item, "value")
                              ) || ""
                            }
                            onChange={handleOnChangeSelect(
                              "application_status_id"
                            )}
                            options={miscFeeOptions}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-3 form-row">
                        <div className="form-input-group col-12">
                          <label htmlFor="last_name" className="full-label mb-0">
                            Amount{" "}
                            {validateForm.renderMessage("amount")}
                          </label>
                          <NumberFormat
                            className="full-input text-uppercase"
                            type="text"
                            name="amount"
                            id="amount"
                            allowLeadingZeros
                            decimalScale={2}
                            decimalSeparator="."
                            disabled={
                              (_.get(miscFeeOptions?.find(item =>
                                _.get(formData, "application_status_id") ===
                                _.get(item, "value")
                              ), 'data.attributes.amount') !== "0.00")
                            }
                            value={_.get(formData, "amount", 0) || ""}
                            onChange={onChangeNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <MiscellaneousFees selectedModuleAndTransaction={formData} />
                  </div>
                </div>
              </div>
            </>
          )}
        {_.get(selectedModuleSystem, "attributes.is_active", 0) === 1 &&
          _.get(selectedModuleSystem, "attributes.is_coming_soon", 0) === 0 &&
          _.get(selectedModuleSystem, "attributes.description", 0) !== 'Miscellaneous Fees' && (
            <>
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="form-wrapper mb-3">
                    <div className="full-group ">
                      <div className="form-row">
                        <div className="form-input-group col-12">
                          <label
                            htmlFor="last_name"
                            className="full-label mb-0"
                          >
                            Select Transaction Type
                            {validateForm.renderMessage("application_status_id")}
                          </label>
                          <Select
                            styles={reactSelectCustomStyle}
                            value={
                              options.find(
                                item =>
                                  _.get(formData, "application_status_id") ===
                                  _.get(item, "value")
                              ) || ""
                            }
                            onChange={handleOnChangeSelect(
                              "application_status_id"
                            )}
                            options={options}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {_.get(CMS_COMPONENTS, "RPTAX") === "ANGELES" &&
                      _.get(formData, "module_type") === "RPTAX" &&
                      _.get(formData, "transaction_type") === "TAX PAYMENT" && (
                        <RPTSearchBilling module={formData} />
                      )}
                    {/* {_.get(formData, "module_type") === "RPTAX" &&
                    _.get(formData, "transaction_type") === "TAX PAYMENT" &&
                    loadRPTAX(
                      _.get(billingSettings, "0.attributes.source"),
                      formData
                    )} */}
                    {_.get(formData, "module_type") === "T_BPLS" &&
                      _.get(formData, "transaction_type") === "QUARTERLY" &&
                      loadTBPLS(
                        _.get(bplsBillingSettings, "1.attributes.source"),
                        formData
                      )}
                    {_.get(formData, "module_type") === "T_BPLS" &&
                      _.get(formData, "transaction_type") === "DELINQUENT" &&
                      loadTBPLS(
                        _.get(bplsBillingSettings, "1.attributes.source"),
                        formData
                      )}

                    {_.get(formData, "module_type") === "BTAX" &&
                      _.get(formData, "transaction_type") === "TAX PAYMENT" &&
                      loadBTAX(
                        _.get(billingSettings, "1.attributes.source"),
                        formData
                      )}
                    {_.get(formData, "module_type") === "NOV" &&
                      _.get(formData, "transaction_type") === "NOV PAYMENT" && (
                        <NOVSearchBilling module={formData} />
                      )}
                  </div>
                </div>
              </div>
              {hasProceedButton().indexOf(_.get(formData, "module_type")) <
                0 && (
                  <div className="row d-flex justify-content-center mb-5">
                    <div className="col-12 col-md-10 col-lg-8 p-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg w-100 cms-bg-color-primary"
                        onClick={handleOnProceed}
                        disabled={_.isEmpty(
                          _.get(formData, "application_status_id")
                        )}
                        style={{
                          cursor: _.isEmpty(
                            _.get(formData, "application_status_id")
                          )
                            ? "not-allowed"
                            : "pointer"
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                )}
              {hasProceedButton().indexOf(_.get(formData, "module_type")) >
                -1 && <div className="pb-5" />}
            </>
          )}
      </>
    );
  };

  const renderIcon = () => {
    // if (isActive) {
    //   return `fa-check text-success`;
    // } else if (!isActive && name === "PSA") {
    //   return `fa-check text-success`;
    // } else if (!isActive && name === "OVRS") {
    //   return `fa-check text-success`;
    // } else if (!isActive && name === "CDL") {
    //   return `fa-check text-success`;
    // } else if (!isActive && name === "MSAB") {
    //   return `fa-check text-success`;
    // }

    return `fa-check text-success`;
  };

  const renderModuleList = () => (
    <div className="row">
      {moduleTypeList.length > 0 ? (
        moduleTypeList
          .filter(x => _.get(x, "attributes.is_active"))
          .map(item => {
            return (
              <React.Fragment key={item.id}>
                <button
                  key={`module-${item.id}`}
                  type="button"
                  // disabled={+!_.get(item, 'attributes.is_active')}
                  className="transaction-button col-12 col-md-6 p-2 btn-blank"
                  title={
                    +_.get(item, "attributes.is_active")
                      ? _.get(item, "attributes.description")
                      : "Coming Soon!"
                  }
                  onClick={handleSetActiveContent(item)}
                >
                  <div className="item-card animated fadeIn">
                    <div className="container-fluid h-100">
                      <div className="row d-flex align-items-center h-100">
                        <div className="col-4 align-self-center">
                          {/* {+_.get(item, 'attributes.is_active', 0) === 0 && (
                          <i className="fa fa-times text-danger h3 icon-circle" style={{ top: 0, left: 10, position: 'absolute' }} />
                        )}
                        {+_.get(item, 'attributes.is_active', 1) === 1 && (
                          <i className="fa fa-check text-success h3 icon-circle" style={{ top: 0, left: 10, position: 'absolute' }} />
                        )} */}
                          <i
                            className={`fa ${renderIcon(
                              _.get(item, "attributes.is_active"),
                              _.get(item, "attributes.name")
                            )} h3 icon-circle`}
                            style={{ top: 0, left: 10, position: "absolute" }}
                          />
                          <img
                            className="transaction-icon"
                            src={
                              _.get(item, "attributes.other_information")
                                ? _.get(
                                  _.get(
                                    CMS_ICONS,
                                    _.get(
                                      item,
                                      "attributes.other_information"
                                    ),
                                    {}
                                  ),
                                  "cdnUrl",
                                  ""
                                )
                                : imgPlaceHolder
                            }
                            alt={_.get(item, "name")}
                          />
                        </div>
                        <div className="col-8 align-self-center pl-0 text-left">
                          <h3 className="transaction-description cms-text-primary cms-text-primary">
                            {_.get(item, "attributes.description") || ""}
                          </h3>
                          <h6>
                            {_.get(item, "attributes.description_filipino") ||
                              ""}
                          </h6>
                          {/* <i className='text-info'>
                          {_.get(item, "attributes.description") || ""}
                        </i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </React.Fragment>
            );
          })
      ) : (
        <div className="col-12">
          <EmptyState />
        </div>
      )}
    </div>
  );

  return (
    <Modal.Body className="clear-modal transaction-modal">
      <button
        type="button"
        className="close modal-close"
        onClick={handleOnClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            {activeContent ? (
              <button
                type="button"
                className="btn btn-link back-button text-primary cms-text-primary"
                onClick={handleSetActiveContent(0)}
              >
                <i className="fas fa-chevron-left" />
                <span className="text">Back</span>
              </button>
            ) : (
              ""
            )}
            <div className="transaction-title pt-4">
              <h2 className="d-inline-block">
                <img
                  className="transaction-head-icon mr-2 mt-0 d-inline-block"
                  src={iconSearch}
                  alt=""
                />
                Select Transaction
              </h2>
            </div>
          </div>
        </div>
        {activeContent ? (
          renderSelectedModule()
        ) : (
          <>
            {isLoading ? (
              <div
                className="d-flex align-items-center"
                style={{
                  height: 438
                }}
              >
                <MiniLoader />
              </div>
            ) : (
              renderModuleList()
            )}
          </>
        )}
      </div>
    </Modal.Body>
  );
}

SelectTransactionModal.defaultProps = {
  initActiveContent: 0,
  initFormData: {}
};

SelectTransactionModal.propTypes = {
  moduleTypeList: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSelectedModuleSystem: PropTypes.instanceOf(Function).isRequired,
  getBillingsImport: PropTypes.instanceOf(Function).isRequired,
  // getBPLSBillingsImport: PropTypes.instanceOf(Function).isRequired,
  selectedModuleSystem: PropTypes.instanceOf(Object).isRequired,
  selectedModuleSystemIsLoading: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  initFormData: PropTypes.instanceOf(Object),
  billingSettings: PropTypes.instanceOf(Object).isRequired,
  bplsBillingSettings: PropTypes.instanceOf(Object).isRequired,
  initActiveContent: PropTypes.number,
  getOfficesList: PropTypes.instanceOf(Function).isRequired,
  officesList: PropTypes.instanceOf(Array).isRequired,
  getMiscFeesList: PropTypes.instanceOf(Function).isRequired,
  miscFeesList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  moduleTypeList: _.get(api, `${c.GET_MODULE_TYPE_LIST}`, []).list || [],
  isLoading: _.get(api, "loading", []).indexOf(c.GET_MODULE_TYPE_LIST) > -1,
  selectedModuleSystemIsLoading:
    _.get(api, "loading", []).indexOf(c.GET_SELECTED_MODULE_SYSTEM) > -1,
  selectedModuleSystem:
    _.get(api, `${c.GET_SELECTED_MODULE_SYSTEM}.item`) || {},
  billingSettings: _.get(api, `${c.GET_BILLINGS_IMPORT}.item`) || {},
  bplsBillingSettings: _.get(api, `${c.GET_BPLS_BILLINGS_IMPORT}.item`) || {},
  officesList: _.get(api, `${c.GET_OFFICES_LIST}`, []).list || [],
  miscFeesList: _.get(api, `${c.GET_MISC_FEES_LIST}`, []).list || [],
});
const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(SelectTransactionModal);
